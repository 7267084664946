import React from 'react';
import { Image } from 'antd';
import cx from 'classnames';
import ModuleItem from '@components/ModuleItem';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const { dataSource } = props;

  return (
    <>
      {dataSource?.map((item: any, index: number) => (
        <ModuleItem title={item.title} theme={index % 2 ? 'grey' : 'white'}>
          <div className={cx(S.item, index % 2 ? '' : S.reverse)}>
            <div className={S.textWrap}>
              {item.describe.map((item: any) => (
                <dl className={S.describe}>
                  <dt>{item.title}</dt>
                  <dd>{item.content}</dd>
                </dl>
              ))}
            </div>

            <div className={S.imageWrap}>
              <Image src={item.image} preview={false} />
            </div>
          </div>
        </ModuleItem>
      ))}
    </>
  );
}
