import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MainWidth } from '@components/MainLayout';
// @ts-ignore
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const { dataSource } = props;
  const { title, subtitle, strongContent, content, video } = dataSource;
  const videoNode = useRef<any>();

  useEffect(() => {
    let player: any = null; // 播放器实例
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      sources: [
        {
          src: video,
          type: 'video/mp4',
        },
      ],
    };

    player =
      videoNode.current && videojs(videoNode.current, videoJsOptions, function onPlayerReady() {});

    return () => {
      player && player.dispose();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={S.banner}>
        <video src={require('./images/01.mp4')} className={S.videoItem} autoPlay loop muted />

        <MainWidth className={S.mainWidth}>
          <div className={S.textWrap}>
            <p className={S.title}>{title}</p>
            <p className={S.subtitle}>{subtitle}</p>

            <Link to="/about-us?prevMenu=aboutUs">
              <button className={S.btn}>了解更多</button>
            </Link>
          </div>
        </MainWidth>
      </div>

      <div className={S.describe}>
        <MainWidth className={S.innerWrap}>
          <div className={S.videoWrap}>
            <video
              ref={videoNode}
              className="video-js vjs-big-play-centered vjs-default-skin"
            ></video>
          </div>

          <article>
            <strong>{strongContent}</strong>
            {content}
          </article>
        </MainWidth>
      </div>
    </>
  );
}
