import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { MainWidth } from '@components/MainLayout';
import { useLocation } from 'react-router-dom';
import { IDetail } from '../typings';

// @ts-ignore
import S from './index.module.less';
import { POST, prefix } from '@utils/lib-request/request';

const TypicalDetail: React.FC = () => {
  const location: any = useLocation();
  const [caseDetail, setCaseDetail] = useState<IDetail>();
  useEffect(() => {
    getDetail(location.state.id);
  }, []); // eslint-disable-line
  const getDetail = async (id: string) => {
    const res: IDetail = await POST(`${prefix}/case/info/${id}`);
    if (res) {
      setCaseDetail(res);
    }
  };
  const goNext = (id: string) => {
    getDetail(id);
  };
  return (
    <div className={S.typicalDetail}>
      <MainWidth>
        <div className={S.bread}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item href="/">首页</Breadcrumb.Item>
            <Breadcrumb.Item href="/typical-cases">新闻动态</Breadcrumb.Item>
            <Breadcrumb.Item href="">{caseDetail?.caseTitle}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className={S.mainCont}>
          <div className={S.mainBox}>
            <h2 className={S.mianTitle}>{caseDetail?.caseTitle}</h2>
            <p className={S.time}>发布时间：{caseDetail?.createdTime}</p>
            <div className={S.line}></div>
            <div dangerouslySetInnerHTML={{ __html: caseDetail?.caseContent || '' }}></div>
            <div className={S.tagBox}>
              {caseDetail?.casePlatformName.split(',').map((tag, tagIndex) => {
                return (
                  <div className={`${S.tag} ${S.blueTag}`} key={tagIndex}>
                    {tag}
                  </div>
                );
              })}
              {caseDetail?.caseSceneName.split(',').map((tag, tagIndex) => {
                return (
                  <div className={`${S.tag} ${S.orangeTag}`} key={tagIndex}>
                    {tag}
                  </div>
                );
              })}
            </div>
            <div className={S.line}></div>
            {caseDetail?.nextCaseId !== '0' && (
              <div className={S.nextArticl} onClick={() => goNext(caseDetail!.nextCaseId)}>
                下一篇：{caseDetail?.nextCaseTitle}
              </div>
            )}
          </div>
        </div>
      </MainWidth>
    </div>
  );
};

export default TypicalDetail;
