import React from 'react';
import { Image } from 'antd';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ModuleItem from '@components/ModuleItem';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

SwiperCore.use([Navigation, Autoplay]);

export default function (props: any) {
  const { dataSource } = props;
  let $swiper: any = null;

  // 轮播配置项
  const settings = {
    onSwiper: (swiper: any) => {
      $swiper = swiper;
    },
    loop: false,
    grabCursor: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    spaceBetween: 0,
    slidesPerView: 3,
    slidesPerGroup: 3,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  };

  const swiperList = dataSource.map((item: any) => (
    <SwiperSlide key={item.src}>
      <div className={S.carouselItem}>
        <Image src={item.src} preview={false} />
      </div>
    </SwiperSlide>
  ));

  const onMouseEnter = () => {
    $swiper && $swiper.autoplay.stop();
  };

  const onMouseLeave = () => {
    $swiper && $swiper.autoplay.start();
  };

  return (
    <ModuleItem title="成就与荣誉" theme="white">
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={S.swiperWrap}>
        <Swiper {...settings}>{swiperList}</Swiper>

        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </div>
    </ModuleItem>
  );
}
