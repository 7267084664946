import React from 'react';
import imageBg from './images/05.png';
import image1 from './images/01.png';
import image2 from './images/02.png';
import image3 from './images/03.png';
import image4 from './images/04.png';

import image11 from './components/Describe/images/1-1.png';
import image12 from './components/Describe/images/1-2.png';
import image13 from './components/Describe/images/1-3.png';
import image14 from './components/Describe/images/1-4.png';

import image21 from './components/Describe/images/2-1.png';
import image22 from './components/Describe/images/2-2.png';

import image31 from './components/Describe/images/3-1.png';
import image32 from './components/Describe/images/3-2.png';

import image41 from './components/Describe/images/4-1.png';
import image42 from './components/Describe/images/4-2.png';

import imageP1 from './components/Product/images/1.png';
import imageP2 from './components/Product/images/2.png';
import imageP3 from './components/Product/images/3.png';
import imageP4 from './components/Product/images/4.png';

/**
 * gyljr:供应链金融
 * kjmy:跨境贸易
 * znzz:智能制造
 * sygl:溯源管理
 */

const dataSources = {
  gyljr: {
    SubBanner: {
      title: '供应链金融',
      subTitle:
        '应用星火·链网区块链技术，打破供应链各环节数据孤岛，有效保护数据隐私，赋能供应链金融',
      backgroundImage: imageBg,
      foregroundImage: image1,
    },
    Describe: [
      {
        title: '解决方案一：应收账款质押融资',
        image: image11,
        describe: [
          {
            title: '场景痛点',
            content: (
              <>
                ꔷ 中小企业融资难、融资贵 <br />
                ꔷ 产业链条信息孤岛、不透明信任，供应链上的企业无法进行有效的协同共享 <br />
                ꔷ 核心企业信用无法满足多级传递 <br />
              </>
            ),
          },
          {
            title: '方案特点',
            content: `基于星火·链网的分布式账本技术具有不可篡改、可追溯的特性，应收账款作为实现基于核心企业信用的应收账款凭证在供应链上的多级流转，支持拆分、转让、融资等业务场景，有效穿透多级供应商外并实出现了核心企业信用的多级传导，通过引入外部金融机构，为应收账款提供低成本的融资服务，多在业务流程的企业授信和融资贷款中发挥关键作用。`,
          },
          {
            title: '方案价值',
            content: (
              <>
                ꔷ 降低融资成本 <br />
                ꔷ 助力维护供应链 <br />
                ꔷ 降低融资风险 <br />
                ꔷ 推动实体经济发展 <br />
              </>
            ),
          },
        ],
      },
      {
        title: '解决方案二：仓单融资',
        image: image12,
        describe: [
          {
            title: '场景痛点',
            content: (
              <>
                ꔷ 重复质押以及仓单造假严重 <br />
                ꔷ 非标商品交易困难，业务覆盖度低 <br />
                ꔷ 手续复杂，企业成本高 <br />
                ꔷ 纸件合同以及暗箱操作导致无法监管 <br />
              </>
            ),
          },
          {
            title: '方案特点',
            content: `仓单监管基于传统的WMS场景和业务，通过星火·链网的区块链技术，联合仓储、物流公司以及保险、质检机构，保证货物的全流程监管，仓单及交易信息全程上链。依托核心机构的权威性建立仓单市场联盟，有利于实现核心企业的信用传递，提高仓单的信用水平。对于构建组织间的互信关系，提高仓单信息的可信度，跟踪和记录仓单流转的完整信息具有重要意义。`,
          },
          {
            title: '方案价值',
            content: (
              <>
                ꔷ 杜绝仓单造假，确保资金安全 <br />
                ꔷ 提出区块链与物联网技术相互结合 <br />
                ꔷ 降低企业成本，促进商品流通 <br />
                ꔷ 接入更多仓储公司，扩大业务覆盖度 <br />
                ꔷ 为中小企业仓单流通提供渠道，防止仓单重复融资 <br />
              </>
            ),
          },
        ],
      },
      {
        title: '解决方案三：金融监管仓',
        image: image13,
        describe: [
          {
            title: '场景痛点',
            content: <>仓库内物品易货风险大、监守自盗屡见不鲜，监管耗费人力物力成本较大</>,
          },
          {
            title: '方案特点',
            content: `通过对普通仓库进行含有芯片技术的物联网设备的改造，将设备与系统对接，运用区块链、大数据、人工智能等手段技术的有机结合，为仓库全面赋能，增强监管手段、提升监管能力 ，且物联网设备数据通过芯片技术实时上传至星火·链网，以实现监管过程数据上链存证。`,
          },
          {
            title: '方案价值',
            content: (
              <>
                ꔷ 提高仓库科技含量，简化管理 <br />
                ꔷ 系统化操作，提升作业效率 <br />
                ꔷ 核心数据上链，不可篡改 <br />
              </>
            ),
          },
        ],
      },
      {
        title: '解决方案四：贸易金融',
        image: image14,
        describe: [
          {
            title: '场景痛点',
            content: <>贸易参与方较多、各环节风控人力物力成本较大</>,
          },
          {
            title: '方案特点',
            content: `基于真实的贸易场景，实现贸易的物流、信息流、商流、资金流全程可视化管理，并基于星火·链网的区块链技术，将贸易过程中参与方在关键环节的操作信息的实时状态以《供应链贸易轨迹凭证》的形式展示在骨干节点上，以国家链网为信用背书，为资金方提供贸易金融的全流程风控管理及展示。`,
          },
          {
            title: '方案价值',
            content: <>贸易行为留痕、数据凭证标准化、数据加密传输、隐私保护</>,
          },
        ],
      },
    ],
    Plant: [
      {
        title: '数据安全流转',
        describe: `基于星火·链网的区块链底层技术，将数据权属信息记录到区块链，运用加密处理、私钥签名等功能，实现数据安全流转，为供应链各参与方数据安全使用提供保障`,
      },
      {
        title: '产融协同',
        describe: `基于星火·链网赋予的分布式数字身份BID，可实现跨企业跨组织的身份认证，解决了跨机构的身份认证和数据合作难题`,
      },
      {
        title: '数据协同',
        describe: `可实现多链、多系统融合的信息基础设施，提供数据治理、数据确权、数据流通“三位一体”的互联网安全信任体系，有效提升数据系统的安全性、可靠性，保护数据隐私，做到安全共享的同时，实现可追溯、可监管，实现数据协同`,
      },
      {
        title: '数据存证',
        describe: `过程及关键数据上链存证，基于星火·链网区块链技术，使数据拥有不可篡改、可追溯的特性，且能使有效留存数字资产`,
      },
    ],
    Product: [
      {
        title: '分布式数字身份',
        image: imageP1,
        content: `去中心化的可验证的数字标识符，具有分布式、自主可控、跨链复用等特点，实现跨企业跨组织的身份认证。`,
      },
      {
        title: '数字协同邮箱',
        image: imageP2,
        content: `通过数字协同邮箱的形式进行跨企业数据凭证的发送，配合数字身份，保证邮件传输数据信息的绝对安全。`,
      },
      {
        title: '数据保险箱',
        image: imageP3,
        content: `数据保险箱是在骨干节点链上设置的单独存储空间，用户上传文件采用区块链技术，保证上传资料存储的绝对安全。`,
      },
      {
        title: '供应链贸易轨迹凭证',
        image: imageP4,
        content: `贯穿贸易的全流程，以货物为维度记录所有参与人的关键节点的操作，且关键节点均会进行上链存证，可链上查证不可篡改。`,
      },
    ],
  },
  kjmy: {
    SubBanner: {
      title: '跨境贸易',
      subTitle:
        '通过数据存证及分布式结算系统，构建良好的跨境连通能力，为国际贸易中的跨境物流及跨境结算提供强有力支撑',
      backgroundImage: imageBg,
      foregroundImage: image2,
    },
    Describe: [
      {
        title: '解决方案一：跨境物流',
        image: image21,
        describe: [
          {
            title: '场景痛点',
            content: (
              <>
                在外贸领域的物流、报关等方面票据的复杂性和往来的交叉性，导致的高人力、时间成本较高
              </>
            ),
          },
          {
            title: '方案特点',
            content: `星火·链网可实现国内外各方的互联互通，采用区块链技术实现的数字单证，可将跨境信息在第一时间内记录、共享、查证，大幅提高单证的安全性，从而保证运输信息的透明化，有助于海关部门进行全面管理监控，有效提高国际贸易的效率`,
          },
          {
            title: '方案价值',
            content: (
              <>
                ꔷ 实现数字单证 <br />
                ꔷ 信息透明化 <br />
                ꔷ 提升效率 <br />
              </>
            ),
          },
        ],
      },
      {
        title: '解决方案二：跨境结算',
        image: image22,
        describe: [
          {
            title: '场景痛点',
            content: (
              <>
                ꔷ 支付效率低 <br />
                ꔷ 难以保证交易信息的隐私性和安全性 <br />
                ꔷ 中心化结算流程缓慢 <br />
              </>
            ),
          },
          {
            title: '方案特点',
            content: `通过星火·链网的区块链技术可以构建分布式结算系统，并通过智能合约完成跨境清结算一体化，大大降低交易成本。在跨境结算上，消除境内外贸易结算的合作屏障，实现高效，安全，可控的自主国际结算能力。`,
          },
          {
            title: '方案价值',
            content: <>省掉繁琐中间环节，智能合约快速结算</>,
          },
        ],
      },
    ],
    Plant: [
      {
        title: '分布式结算系统',
        describe: `
        通过数据存证及分布式结算系统，构建良好的跨境连通能力，为国际贸易中的跨境物流及跨境结算提供强有力支撑
      `,
      },
      {
        title: '跨境连通能力',
        describe: `
        星火·链网旨在打造万物互联的可信生态，未来会链接国际上多个国家共同发展，不断提升链接国际的能力，接入更多跨境环节中有效数据
      `,
      },
      {
        title: '数据安全可信',
        describe: `
        通过基于星火·链网的访问权限控制和基于密码学安全算法的密钥管理机制，有效控制用户对于平台数据的访问权限，保护数据的安全性
      `,
      },
      {
        title: '数据存证',
        describe: `
        过程及关键数据上链存证，基于星火·链网区块链技术，使数据拥有不可篡改、可追溯的特性，且能使有效留存数字资产
      `,
      },
    ],
    Product: [
      {
        title: '分布式数字身份',
        image: imageP1,
        content: `去中心化的可验证的数字标识符，具有分布式、自主可控、跨链复用等特点，实现跨企业跨组织的身份认证。`,
      },
      {
        title: '数字协同邮箱',
        image: imageP2,
        content: `
        通过数字协同邮箱的形式进行跨企业数据凭证的发送，配合数字身份，保证邮件传输数据信息的绝对安全。
      `,
      },
      {
        title: '数据保险箱',
        image: imageP3,
        content: `
        数据保险箱是在骨干节点链上设置的单独存储空间，用户上传文件采用区块链技术，保证上传资料存储的绝对安全。
      `,
      },
    ],
  },
  znzz: {
    SubBanner: {
      title: '智能制造',
      subTitle:
        '实现从零部件到成品全生命周期数据的可追溯，可执行，可感知，使工业制造的不同参与方智能化，网络化，自动化，实现降本增效',
      backgroundImage: imageBg,
      foregroundImage: image4,
    },
    Describe: [
      {
        title: '解决方案一：智能生产管控',
        image: image31,
        describe: [
          {
            title: '场景痛点',
            content: (
              <>制造业涉及的零部件数量超多、结构复杂，中间商流转环节较多，很难统一管理、统筹安排</>
            ),
          },
          {
            title: '方案特点',
            content: `
            智能制造是打造“两个强国”的重要实现方式，以区块链技术为支撑的工业互联网中的智能制造，是星火·链网的核心能力。通过星火·链网，可实现从零部件到成品全生命周期数据的可追溯，可执行，可感知。
          `,
          },
          {
            title: '方案价值',
            content: <>使工业制造的不同参与方智能化，网络化，自动化，实现降本增效。</>,
          },
        ],
      },
      {
        title: '解决方案二：制造企业数字化',
        image: image32,
        describe: [
          {
            title: '场景痛点',
            content: <>数据孤岛难打通、企业普遍缺少平台化建设</>,
          },
          {
            title: '方案特点',
            content: `
            构建基于星火·链网作为后台账本系统的设备身份管理平台，以智能合约共识执行的方式获取和验证设备身份，并且建立从个人实体身份到所拥有的端设备身份之间的映射关系，实现设备端与使用者之间双向可信交互。
          `,
          },
          {
            title: '方案价值',
            content: <>留存数字资产</>,
          },
        ],
      },
    ],
    Plant: [
      {
        title: '链网协同',
        describe: `
        区块链工业互联网深度融合，区块链作为信任机器，提供一种安全、高效、可信的技术方法，为解决机构与机构、人与人、设备与设备之间的高效协作问题带来机遇；工业互联网采用树状层次化治理架构搭建网络标识解析体系，为万物互联提供解决方案
      `,
      },
      {
        title: '分布式数字身份',
        describe: `
        星火·链网的分布式数字身份标识BID，是一种去中心化的可验证的数字标识符，具有分布式、自主可控、跨链使用等特点，是可赋予人、企业、设备等的全链网唯一身份标识，应用数字身份标识BID，可免去不同机构间的重复注册，实现跨企业跨组织的身份认证
      `,
      },
      {
        title: '新型标识',
        describe: `
        对新型分布式标识及现有标识体系的多标识根区数据资源管理服务，实现根区平等共治、资源自主管理，提供同时兼容新型分布式标识体系和现有标识体系解析模式，实现多标识体系解析过程互联互通
      `,
      },
      {
        title: '数据共享',
        describe: `
        可实现多链、多系统融合的信息基础设施，提供数据治理、数据确权、数据流通“三位一体”的互联网安全信任体系，有效提升数据系统的安全性、可靠性，保护数据隐私，做到数据安全共享
      `,
      },
    ],
    Product: [
      {
        title: '分布式数字身份',
        image: imageP1,
        content: `去中心化的可验证的数字标识符，具有分布式、自主可控、跨链复用等特点，实现跨企业跨组织的身份认证。`,
      },
      {
        title: '数字协同邮箱',
        image: imageP2,
        content: `
        通过数字协同邮箱的形式进行跨企业数据凭证的发送，配合数字身份，保证邮件传输数据信息的绝对安全。
      `,
      },
      {
        title: '数据保险箱',
        image: imageP3,
        content: `
        数据保险箱是在骨干节点链上设置的单独存储空间，用户上传文件采用区块链技术，保证上传资料存储的绝对安全。
      `,
      },
    ],
  },
  sygl: {
    SubBanner: {
      title: '溯源管理',
      subTitle:
        '星火·链网运用区块链技术与工业互联网标识解析体系相结合，提供商品跨境及原产地供应链溯源整体解决方案',
      backgroundImage: imageBg,
      foregroundImage: image3,
    },
    Describe: [
      {
        title: '解决方案一：商品溯源',
        image: image41,
        describe: [
          {
            title: '场景痛点',
            content: (
              <>
                商品信息不对称，商品供应链中产、供、销环节数据易被篡改，供应链参与方协作效率低，基础的区块链系统建设成本高
              </>
            ),
          },
          {
            title: '方案特点',
            content: `
            通过星火·链网区块链技术对商品的生产、流通、消费等供应链全生命周期进行监控。利用区块链的去中心化、防篡改、分布式存储等特点，实现商品的源头可查，流通过程可跟踪，销售质量可保证，帮助全生命周期中的各参与方企业建立起健康的供应链生态。商品一物一码，全过程流转的信息写入区块链，链上信息不可篡改，确保商品的唯一性。
          `,
          },
          {
            title: '方案价值',
            content: (
              <>
                ꔷ 商品从生产到销售全流程追溯，出现纠纷后，可快速定位问题环节；
                <br />
                ꔷ
                有效防止假冒产品流入市场，避免经济和信誉损失。对商品溯本还原,全面提升管理能力，增强品牌信誉。
                <br />
              </>
            ),
          },
        ],
      },
      {
        title: '解决方案二：供应链溯源',
        image: image42,
        describe: [
          {
            title: '场景痛点',
            content: <>链条长、环节多、参与主体繁杂、数据孤岛难融合</>,
          },
          {
            title: '方案特点',
            content: `
            基于星火·链网的全新信任体系，通过胶州骨干节点，采用分布式数字身份BID技术，实现供应链产业数字化互联互通，在有效保护数据隐私与安全的前提下，达到不同参与主体数据共享互认的产业数字化目标。同时，也为防控供应链金融的市场风险和操作风险，实现供应链四流合一提供了坚实的保障。
          `,
          },
          {
            title: '方案价值',
            content: (
              <>
                ꔷ 实现供应链产业数字化互联互通
                <br />
                ꔷ 有效保护数据隐私
                <br />
                ꔷ 数据可安全流转
                <br />
              </>
            ),
          },
        ],
      },
    ],
    Plant: [
      {
        title: '一物一码',
        describe: `
        依托星火·链网的区块链加密技术，每个溯源码都唯一加密，实现“一物一码”、保证溯源码唯一可验证，不可破译和篡改
      `,
      },
      {
        title: '分布式数字身份',
        describe: `
        星火·链网的分布式数字身份标识BID，是一种去中心化的可验证的数字标识符， 具有分布式、自主可控、跨链使用等特点，是可赋予人、企业、设备等的全链网唯一身份标识，应用数字身份标识BID，可免去不同机构间的重复注册，实现跨企业跨组织的身份认证
      `,
      },
      {
        title: '新型标识',
        describe: `
        对新型分布式标识及现有标识体系的多标识根区数据资源管理服务，实现根区平等共治、资源自主管理，提供同时兼容新型分布式标识体系和现有标识体系解析模式，实现多标识体系解析过程互联互通
      `,
      },
      {
        title: '智能监控',
        describe: `
        与物联网、人工智能等手段相结合，运用 数据直接上链的手段，实现全流程智能监管，各环节数据实时监控，不可篡改
      `,
      },
    ],
    Product: [
      {
        title: '分布式数字身份',
        image: imageP1,
        content: `
        去中心化的可验证的数字标识符，具有分布式、自主可控、跨链复用等特点，实现跨企业跨组织的身份认证。
      `,
      },
      {
        title: '数字协同邮箱',
        image: imageP2,
        content: `
        通过数字协同邮箱的形式进行跨企业数据凭证的发送，配合数字身份，保证邮件传输数据信息的绝对安全。
      `,
      },
      {
        title: '数据保险箱',
        image: imageP3,
        content: `
        数据保险箱是在骨干节点链上设置的单独存储空间，用户上传文件采用区块链技术，保证上传资料存储的绝对安全。
      `,
      },
      {
        title: '供应链贸易轨迹凭证',
        image: imageP4,
        content: `
        贯穿贸易的全流程，以货物为维度记录所有参与人的关键节点的操作，且关键节点均会进行上链存证，可链上查证不可篡改。
      `,
      },
    ],
  },
};

export default dataSources;
