import React, { useState, useMemo, useEffect } from 'react';
import { Tabs, Affix } from 'antd';
import cx from 'classnames';
import useUrlState from '@ahooksjs/use-url-state';
import { MainWidth } from '@components/MainLayout';
import SubBanner from '@components/SubBanner';
import Describe from './components/Describe';
import Features from './components/Features';
import Scenes from './components/Scenes';
// @ts-ignore
import tabsStylesPc from '../../css/tabs.module.less';
// @ts-ignore
import tabsStylesMobile from '../../css/tabs.mobile.module.less';
// @ts-ignore
import S from './style.module.less';
import { tabsMenu } from './dataSources';
import image1 from './images/bg.png';
import image2 from './images/01.png';
import { isMobile } from '@utils/device';

let tabsStyles = tabsStylesPc;

if (isMobile) {
  tabsStyles = tabsStylesMobile;
}

const { TabPane } = Tabs;

export default function () {
  const [currTabsKey, setCurrTabsKey] = useState<string>();
  const [urlState, setUrlState] = useUrlState();

  const onTabsMenuChange = (key: any) => {
    setCurrTabsKey(key);
    console.log(currTabsKey);
  };

  // 数据源
  const dataSource = useMemo(() => {
    const index = tabsMenu.findIndex(e => e.label === currTabsKey);

    return tabsMenu[index];
  }, [currTabsKey]);

  useEffect(() => {
    currTabsKey &&
      setUrlState({
        currTabsKey,
      });
    // eslint-disable-next-line
  }, [currTabsKey]);

  useEffect(() => {
    setCurrTabsKey(urlState.currTabsKey ? urlState.currTabsKey : tabsMenu[0].label);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SubBanner
        title="产品中心"
        subTitle="基于区块链技术结合分布式数字身份，可信数据存储，可信数据共享，满足业务场景下对全业务节点数据交换，数据可信，数据追溯的管理要求。"
        backgroundImage={image1}
        foregroundImage={image2}
      />

      <Affix className={S.affix}>
        <div className={S.tabsWrap}>
          <MainWidth>
            <Tabs
              activeKey={currTabsKey}
              onChange={onTabsMenuChange}
              animated={true}
              className={cx(tabsStyles.tabs, S.tabs)}
            >
              {tabsMenu.map(item => (
                <TabPane tab={item.label} key={item.label} />
              ))}
            </Tabs>
          </MainWidth>
        </div>
      </Affix>

      <Describe dataSource={dataSource?.describe} />
      <Features dataSource={dataSource?.FeaturesList} />
      <Scenes dataSource={dataSource?.scenes} />
    </>
  );
}
