import React, { useMemo } from 'react';
import SubBanner from '@components/SubBanner';
import Describe from './components/Describe';
import Plant from './components/Plant';
import Product from './components/Product';
import dataSources from './dataSources';

export default function (props: any) {
  const type: 'gyljr' | 'kjmy' | 'znzz' | 'sygl' = props.match.params.type; // 类型
  /**
   * gyljr:供应链金融
   * kjmy:跨境贸易
   * znzz:智能制造
   * sygl:溯源管理
   */

  const data = useMemo(() => {
    return dataSources[type];
  }, [type]);

  return (
    <>
      <SubBanner {...data.SubBanner} />
      <Describe dataSource={data.Describe} />
      <Plant dataSource={data.Plant} />
      <Product dataSource={data.Product} />
    </>
  );
}
