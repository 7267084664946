import React from 'react';
import Banner from './components/Banner';
import Scenes from './components/Scenes';
import Able from './components/Able';
// import Honor from './components/Honor';
import Partner from './components/Partner';
import dataSource from './dataSource';

export default function () {
  return (
    <>
      <Banner dataSource={dataSource.banner} />
      <Scenes dataSource={dataSource.scenes} />
      <Able dataSource={dataSource.able} />
      {/* <Honor dataSource={dataSource.Honor} /> */}
      <Partner dataSource={dataSource.partner} />
    </>
  );
}
