import React from 'react';
import { Image } from 'antd';
import ModuleItem from '@components/ModuleItem';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const { dataSource } = props;
  return (
    <ModuleItem title="合作伙伴" theme="white">
      <div className={S.listWrap}>
        {dataSource.map((item: any) => (
          <p key={item.src} className={S.item}>
            <Image src={item.src} preview={false} alt={item.label} />
          </p>
        ))}
      </div>
    </ModuleItem>
  );
}
