import React, { useState } from 'react';
import ModuleItem from '@components/ModuleItem';
import cx from 'classnames';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const { dataSource } = props;
  const [currentTitle, setCurrentTitle] = useState(0);

  const onMouseOver = (e: any) => {
    setCurrentTitle(e);
  };

  return (
    <ModuleItem title="方案优势" theme={'white'}>
      <div className={S.listWrap}>
        {dataSource?.map((item: any, index: number) => (
          <div
            className={cx(S.item, currentTitle === index ? S.active : '')}
            onMouseOver={() => {
              onMouseOver(index);
            }}
          >
            <p className={S.describe}>{item.describe}</p>
            <p className={S.title}>{item.title}</p>
          </div>
        ))}
      </div>
    </ModuleItem>
  );
}
