import image1 from './components/Features/images/01.png';
import image2 from './components/Features/images/02.png';
import image3 from './components/Features/images/03.png';
import image4 from './components/Features/images/04.png';
import image5 from './components/Features/images/05.png';
import image6 from './components/Features/images/06.png';
import image7 from './components/Features/images/07.png';
import image8 from './components/Features/images/08.png';
import image9 from './components/Features/images/09.png';
import image10 from './components/Features/images/10.png';
import image11 from './components/Features/images/11.png';
import image12 from './components/Features/images/12.png';

import imageD1 from './components/Describe/images/1.png';
import imageD2 from './components/Describe/images/2.png';
import imageD3 from './components/Describe/images/3.png';
import imageD4 from './components/Describe/images/4.png';

// import imageS11 from './components/Scenes/images/1-1.png';
// import imageS12 from './components/Scenes/images/1-2.png';
// import imageS13 from './components/Scenes/images/1-3.png';
// import imageS14 from './components/Scenes/images/1-4.png';
// import imageS21 from './components/Scenes/images/2-1.png';
// import imageS22 from './components/Scenes/images/2-2.png';
// import imageS23 from './components/Scenes/images/2-3.png';
// import imageS41 from './components/Scenes/images/4-1.png';
// import imageS42 from './components/Scenes/images/4-2.png';

import imageS1 from './components/Scenes/images/1.png';
import imageS2 from './components/Scenes/images/2.png';
import imageS3 from './components/Scenes/images/3.png';
import imageS4 from './components/Scenes/images/4.png';

export const tabsMenu = [
  {
    label: '分布式数字身份',
    scenes: [
      {
        title: '跨境贸易',
        content: `
      星火·链网以区块链技术为核心，通过数据存证及分布式结算系统，构建良好的跨境连通能力，为国际贸易中的跨境物流及跨境结算提供强有力支撑。
    `,
        image: imageS1,
        planLink: '/scenes/kjmy?prevMenu=scenes2',
      },
      {
        title: '供应链金融',
        content: `星火·链网打造以区块链为核心的产业集群，链接产业端与金融机构端，加速实现供应链金融中的商流、资金流、物流、信息流四流合一，提高产业集群中企业抗风险能力，扩大企业融资规模。`,
        image: imageS2,
        planLink: '/scenes/gyljr?prevMenu=scenes1',
      },
      {
        title: '智能制造',
        content: `星火·链网打造区块链技术为支撑的工业互联网的核心能力，在智能制造领域，实现从零部件到成品全生命周期数据的可追溯，可执行，可感知。使工业制造的不同参与方智能化，网络化，自动化，实现降本增效。`,
        image: imageS3,
        planLink: '/scenes/znzz?prevMenu=scenes3',
      },
      {
        title: '溯源管理',
        content: `
      星火·链网运用区块链技术与工业互联网标识解析体系相结合，提供商品跨境及原产地供应链溯源整体解决方案。`,
        image: imageS4,
        planLink: '/scenes/sygl?prevMenu=scenes4',
      },
    ],
    describe: {
      title: '分布式数字身份',
      smallTitle: '',
      titleContent: `
      星火·链网的分布式数字身份标识BID，是一种去中心化的可验证的数字标识符，具有分布式、自主可控、跨链复用等特点。实体可自主完成BID的注册、解析、更新或者撤销操作。可为异构系统间提供具备互操作性的统一身份标识，免去不同机构间的重复注册，实现跨企业跨组织的身份认证。
      `,
      subTitle: '产品优势',
      subTitleContent: `提供实体身份的创建、验证和管理等一整套功能，实现更规范化地管理和保护实体数据，同时保证信息流转的真实性和效率，解决了跨机构的身份认证和数据合作难题。`,
      image: imageD1,
    },
    FeaturesList: [
      {
        icon: image1,
        title: '去中心化',
        describe: `基于区块链构建分布式数字身份系统，摆脱了对单一中心企业的依赖，抵御故障和篡改。各生态参与方平权参与，易于生态合作。`,
      },
      {
        icon: image2,
        title: '身份自主控制',
        describe: `打通用户实体和数字身份的关联，并通过用户授权保护个人信息的合理使用`,
      },
      {
        icon: image3,
        title: '安全性高',
        describe: `数字身份标识脱敏，避免敏感数据泄露。实体的现实身份和 VC 可验证数字凭证等个人信息均由用户管理控制，非授权机构无法反向推测出实体的个人信息。`,
      },
    ],
  },
  {
    label: '数字协同邮箱',
    scenes: [
      {
        title: '跨境贸易',
        content: `星火·链网以区块链技术为核心，通过数据存证及分布式结算系统，构建良好的跨境连通能力，为国际贸易中的跨境物流及跨境结算提供强有力支撑。
    `,
        image: imageS1,
        planLink: '/scenes/kjmy?prevMenu=scenes2',
      },
      {
        title: '供应链金融',
        content: `星火·链网打造以区块链为核心的产业集群，链接产业端与金融机构端，加速实现供应链金融中的商流、资金流、物流、信息流四流合一，提高产业集群中企业抗风险能力，扩大企业融资规模。`,
        image: imageS2,
        planLink: '/scenes/gyljr?prevMenu=scenes1',
      },
      {
        title: '溯源管理',
        content: `星火·链网运用区块链技术与工业互联网标识解析体系相结合，提供商品跨境及原产地供应链溯源整体解决方案。`,
        image: imageS4,
        planLink: '/scenes/sygl?prevMenu=scenes4',
      },
    ],
    describe: {
      title: '数字协同邮箱',
      smallTitle: '凭证数据加密交换通道',
      titleContent: `无需系统对接，用户可以通过数字协同邮箱的形式进行跨企业数据凭证的发送。有别于传统形式的邮箱账号申请，免去邮箱注册模式，用户登陆后用系统自动分配数字身份BID建立邮件地址，直接通过邮件形式实现文件发送。传输数据过程保证邮件传输数据信息的绝对安全。`,
      subTitle: '产品优势',
      subTitleContent: `可实现跨企业数字凭证的传输和系统防篡改自动核验功能，省去跨系统对接的难题，提高企业间协作效率`,
      image: imageD2,
    },
    FeaturesList: [
      {
        icon: image4,
        title: '精准发送',
        describe: `可将凭证数据进行点对点精准发送给验证方`,
      },
      {
        icon: image5,
        title: '保密性高',
        describe: `采用区块链技术对信息进行处理，防止数据泄漏`,
      },
      {
        icon: image6,
        title: '传输安全',
        describe: `即使在传输中途被截获，也无法获取邮件信息`,
      },
    ],
  },
  {
    label: '数据保险箱',
    scenes: [
      {
        title: '跨境贸易',
        content: `星火·链网以区块链技术为核心，通过数据存证及分布式结算系统，构建良好的跨境连通能力，为国际贸易中的跨境物流及跨境结算提供强有力支撑。
    `,
        image: imageS1,
        planLink: '/scenes/kjmy?prevMenu=scenes2',
      },
      {
        title: '供应链金融',
        content: `星火·链网打造以区块链为核心的产业集群，链接产业端与金融机构端，加速实现供应链金融中的商流、资金流、物流、信息流四流合一，提高产业集群中企业抗风险能力，扩大企业融资规模。`,
        image: imageS2,
        planLink: '/scenes/gyljr?prevMenu=scenes1',
      },
      {
        title: '溯源管理',
        content: `星火·链网运用区块链技术与工业互联网标识解析体系相结合，提供商品跨境及原产地供应链溯源整体解决方案。`,
        image: imageS4,
        planLink: '/scenes/sygl?prevMenu=scenes4',
      },
    ],
    describe: {
      title: '数据保险箱',
      smallTitle: '凭证数据加密存储空间',
      titleContent: `
      数据保险箱是在骨干节点链上设置的单独存储空间，用户上传文件采用区块链技术，保证上传资料存储的绝对安全。数据保险箱包含我的空间和共享空间两大模块，可自主设置查看权限，在保证海量数据凭证安全存储的前提下，也可以实现数据凭证的点对点安全共享。`,
      subTitle: '产品优势',
      subTitleContent: `
      为用户提供更高安全系数的核心数据凭证存储服务，存储过程也可上链追溯，实现海量数据的存储管理。
      `,
      image: imageD3,
    },
    FeaturesList: [
      {
        icon: image7,
        title: '安全可靠',
        describe: `
      数据保险箱接入密钥管理系统 KMS，为数据提供加密服务
    `,
      },
      {
        icon: image8,
        title: '标识资源',
        describe: `
      数据保险箱提供可视化的控制台界面，帮助用户更好的管理系统，用户通过简单配置即可完成数据保险箱创建、更新、删除等操作
    `,
      },
      {
        icon: image9,
        title: '标识解析',
        describe: `
      用户可以对数据保险箱的访问权限进行管理，赋予用户简单并且全面的权限控制权利
    `,
      },
    ],
  },
  {
    label: '供应链贸易轨迹凭证',
    scenes: [
      {
        title: '供应链金融',
        content: `星火·链网可实现国内外各方的互联互通， 采用区块链技术实现的数字单证，可将跨境信息在第一时间内记录、共享、查证， 大幅提高单证的安全性，从而保证运输信息的透明化， 有助于海关部门进行全面管理监控，有效提有效提有效提有效提有效…`,
        image: imageS2,
        planLink: '/scenes/gyljr?prevMenu=scenes1',
      },
      {
        title: '溯源管理',
        content: `
      星火·链网运用区块链技术与工业互联网标识解析体系相结合，提供商品跨境及原产地供应链溯源整体解决方案。`,
        image: imageS4,
        planLink: '/scenes/sygl?prevMenu=scenes4',
      },
    ],
    describe: {
      title: '供应链贸易轨迹凭证',
      titleContent: `供应链贸易轨迹凭证，贯穿贸易的全流程，将从委托方和代理商签订合同开始到订单货物出库交付结束，记录整个环节当中以货物为维度的所有参与人的关键节点的操作，所有关键节点会进行上链存证，且可链上查证不可篡改。目前可进行上链存证的关键节点如下：在签订合同、生成订单，订单入库，仓储质押，缴清货款，赎货申请，提货申请，出库环节均可进行。实现不同环节参与主体，参与方数据的协同共享，达到高质量，高效率， 高效益贸易的目标。`,
      subTitle: '产品优势',
      subTitleContent: `各参与方贸易凭证数据实时上链存证， 多方数据透明可见，无惧信息孤岛，链上数据随查随取，随查随用。`,
      image: imageD4,
    },
    FeaturesList: [
      {
        icon: image10,
        title: '真实不可篡改',
        describe: `
      采用完全分布式的架构，对数据进行处理，计算和交互在数据的多个计算节点之间独立完成，数据真实保障不可篡改。
    `,
      },
      {
        icon: image11,
        title: '隐私保护',
        describe: `
      数据选择性披露设置，保证数据最小化利用，所有计算均在脱敏之后进行，最大程度的保障个人隐私数据安全。
    `,
      },
      {
        icon: image12,
        title: '透明安全，稳定可靠',
        describe: `
      链上数据全透明，可靠的分布式存储机制，为客户提供稳定的数字凭证一站式服务
    `,
      },
    ],
  },
];
