import React from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { contactUs } from './api';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  let contactModal: any = null; // 弹窗实例

  const submit = async (values: any) => {
    await contactUs(values);

    message.success('提交成功');
    contactModal.destroy();
  };

  const renderModal = () => {
    contactModal = Modal.info({
      className: S.contactModal,
      closable: true,
      icon: null,
      centered: true,
      title: '联系我们',
      width: '40vw',
      okText: '确定',
      content: (
        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 18 }} onFinish={submit} autoComplete="off">
          <Form.Item label="姓名" name="name" rules={[{ required: true, message: '请输入姓名!' }]}>
            <Input placeholder="请输入姓名" />
          </Form.Item>

          <Form.Item
            label="单位"
            name="workUnit"
            rules={[{ required: true, message: '请输入电话!' }]}
          >
            <Input placeholder="请输入单位" />
          </Form.Item>

          <Form.Item
            label="电话"
            name="phone"
            rules={[
              { required: true, message: '请输入电话!' },
              { pattern: /^1[3456789]\d{9}$/, message: '请输入正确格式的电话号码' },
            ]}
          >
            <Input placeholder="请输入电话" />
          </Form.Item>

          <Form.Item
            label="合作需求"
            name="reason"
            rules={[{ required: true, message: '请输入合作需求!' }]}
          >
            <Input.TextArea placeholder="请输入合作需求" />
          </Form.Item>

          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              {
                pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
                message: '请输入正确格式的邮箱',
              },
            ]}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 18 }}>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      ),
    });
  };

  return (
    <div onClick={renderModal} className={S.sideTools}>
      <span className={S.text}>联系我们</span>
    </div>
  );
}
