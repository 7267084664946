import React from 'react';
import SubBanner from '@components/SubBanner';
import Detail from './components/Detail';
import Step from './components/Steps';

import backgroundImage from './images/backgroundImg.png';
import foregroundImage from './images/forgroundImage.png';
import detailImg1 from './components/Detail/images/image1.png';
import detailImg2 from './components/Detail/images/image2.png';
import StepImg1 from './components/Steps/images/img1.png';

const dataSource = {
  SubBanner: {
    title: '关于我们',
    subTitle: '构建万物互联的可信生态',
    backgroundImage,
    foregroundImage,
  },
  Detail: [
    {
      title: '星火·链网',
      article: (
        <article>
          <p>
            数字经济是全球未来的发展方向，世界正进入数字经济快速发展的时期。星火·链网正是这百年变局和机遇中在工信部专项支持下，持续推进产业数字化与数字产业化面向数字经济的新型基础设施。星火·链网基础设施将高效管理数字经济时代下海量的数字化资产，为构建全要素、全产业链、全价值链的“万物互联”提供重要的基础能力支撑，是促进区块链技术集成应用成为中国进行自主基础创新的重大举措。
          </p>
          <p>
            星火·链网是国家新型基础设施建设的重要组成部分。在国家发改委界定的新型基础设施建设范畴中，星火·链网属于新型基础设施中的信息基础设施版块，具体为通信基础设施板块（工业互联网）与新技术基础设施板块（区块链）的融合领域。
          </p>
          <p>
            星火·链网新型基础设施建设由中国信息通信研究院为主要承担单位，组建覆盖全国的区块链网络体系，促进基础设施发挥能效。星火·链网将基于我国现有域名、工业互联网与物联网标识体系建设成果，以区块链技术、去中心化标识技术进行整体规划，以建设节点的形式构建一个完整的国家级区块链新基建生态体系，
            构造万物互联的可信生态。
          </p>
        </article>
      ),
      image: detailImg1,
    },
    {
      title: '胶州骨干节点',
      article: (
        <article>
          <p>
            胶州星火·链网骨干节点是胶州响应国家“十四五”数字经济规划纲要，建设的山东省首个骨干节点，通过连接国家级新型基础设施链核心枢纽，目标打造以区块链为核心的产业集群，全面赋能胶州实体经济，实现当地产业聚集、产业创新。胶州骨干节点以胶州市为核心，立足胶东半岛，以供应链能力向外输出，辐射全国，连接国际，致力于将胶州市区域节点打造为国家级供应链行业服务平台。
          </p>
          <p>
            星火·链网（胶州）骨干节点以云原生区块链+供应链管理+工业互联网为发展方向。基于国家级供应链行业服务平台可以规范行业发展，制定基于区块链的行业标准，提升行业数据质量，同时纳入更多的参与企业与资金方，通过多样化的数据标准使得平台参与企业可以平滑对接金融服务、物联网标识、工业控制等服务。
          </p>
          <p>
            未来胶州市乃至山东省各大政府机构、科技企业，均可通星火·链网（胶州）骨干节点接入国家级新型基础设施星火·链网，打造以区块链为核心的产业集群，全面赋能胶州实体经济，实现当地产业聚集、产业创新，提升胶州本地交易量。
          </p>
        </article>
      ),
      image: detailImg2,
    },
  ],
  Step: {
    image: StepImg1,
  },
};

export default function () {
  return (
    <>
      <SubBanner {...dataSource.SubBanner} />
      <Detail dataSource={dataSource.Detail} />
      <Step dataSource={dataSource.Step} />
    </>
  );
}
