import React from 'react';
import ModuleItem from '@components/ModuleItem';
import image0 from './images/icon-0.png';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const { dataSource } = props;

  return (
    <ModuleItem title="核心能力" theme="grey">
      <div className={S.listWrap}>
        {dataSource.map((item: any) => (
          <div className={S.item}>
            <div className={S.iconWrap}>
              <img className={S.iconUp} src={item.icon} alt="" />
              <img className={S.iconDown} src={image0} alt="" />
            </div>

            <p className={S.title}>{item.title}</p>
            <p className={S.describe}>{item.describe}</p>
          </div>
        ))}
      </div>
    </ModuleItem>
  );
}
