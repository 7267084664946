import React from 'react';
import { Image } from 'antd';
import ModuleItem from '@components/ModuleItem';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const { dataSource } = props;

  return (
    <>
      <ModuleItem renderTitle={() => null} theme={'white'}>
        <div className={S.item}>
          <div className={S.textWrap}>
            <dl className={S.describe}>
              <dt>
                {dataSource?.title}
                {dataSource?.smallTitle && <small>（{dataSource?.smallTitle}）</small>}
              </dt>

              <dd>{dataSource?.titleContent}</dd>
            </dl>

            <dl className={S.subDescribe}>
              <dt>{dataSource?.subTitle}</dt>
              <dd>{dataSource?.subTitleContent}</dd>
            </dl>
          </div>

          <div className={S.imageWrap}>
            <Image src={dataSource?.image} preview={false} />
          </div>
        </div>
      </ModuleItem>
    </>
  );
}
