import React from 'react';
import Footer from '@components/Footer';
import cx from 'classnames';
import Header from '@components/Header';
import SideTools from '@components/SideTools';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

// 这个组件用于页面根组件
export const PageWrap = function (props: any) {
  return (
    <div id="pageWrap" className={cx(S.pageWrap, S.mainWidth, props.className)}>
      {props.children}
    </div>
  );
};

// 当使用了PageWrap组件时，还需要全局主宽度组件时使用该组件
export const MainWidth = function (props: any) {
  return <div className={cx(S.mainWidth, props.className)}>{props.children}</div>;
};

export default function (props: any) {
  return (
    <>
      <Header />
      {props.children}
      <SideTools />
      <Footer />
    </>
  );
}
