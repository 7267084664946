import React from 'react';
import { Image } from 'antd';
import cx from 'classnames';
import { MainWidth } from '@components/MainLayout';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const {
    title = '标题', // 标题
    subTitle = '副标题', // 副标题  可以传入JSX，比方说是一个超链接
    backgroundImage = '', // 背景图
    foregroundImage = '', // 右侧前景图
    className, // 类名
  } = props;

  return (
    <div
      className={cx(S.subBanner, className)}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <MainWidth className={S.mainWidth}>
        <div className={S.textWrap}>
          <p className={S.title}>{title}</p>
          <p className={S.subtitle}>{subTitle}</p>
        </div>

        <div className={S.imageWrap}>
          <Image src={foregroundImage} preview={false} />
        </div>
      </MainWidth>
    </div>
  );
}
