import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import Routers from './Routers';
import './css/_commonReset.less';

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <ErrorBoundary>
      <Routers />
    </ErrorBoundary>
  </ConfigProvider>,
  document.getElementById('root'),
);
