import React from 'react';
import { Image } from 'antd';
import ModuleItem from '@components/ModuleItem';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const { dataSource } = props;
  return (
    <ModuleItem title="相关应用场景" theme="white">
      <div className={S.listWrap}>
        {dataSource?.map((item: any) => (
          <div className={S.item}>
            <div className={S.imageWrap}>
              <Image src={item.image} preview={false} />
              <div className={S.mask}>
                <p className={S.describe}>{item.content}</p>

                <a href={item.planLink}>
                  <button>了解更多</button>
                </a>
              </div>
            </div>

            <p className={S.title}>{item.title}</p>
          </div>
        ))}
      </div>
    </ModuleItem>
  );
}
