import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Image } from 'antd';
import cx from 'classnames';
import ModuleItem from '@components/ModuleItem';
// @ts-ignore
import tabsStyles1 from '../../../../css/tabs.module.less';
// @ts-ignore
import tabsStyles2 from '../../../../css/tabs.mobile.module.less';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let tabsStyles = tabsStyles1;
let S = Spc;

if (isMobile) {
  S = Smobile;
  tabsStyles = tabsStyles2;
}

const { TabPane } = Tabs;

export default function (props: any) {
  const { dataSource } = props;

  const renderItem = (item: any) => (
    <div className={S.item}>
      <div className={S.textWrap}>
        <p className={S.describe}>{item.describe}</p>

        <dl className={S.aside}>
          <dt>解决方案</dt>
          <dd>
            <p className={S.plan}>{item.plan}</p>
            <Link to={item.planLink}>
              <button>查看详情</button>
            </Link>
          </dd>
        </dl>
      </div>

      <div className={S.imageWrap}>
        <Image src={item.image} preview={false} />
      </div>
    </div>
  );

  return (
    <ModuleItem title="应用场景" theme="white">
      <Tabs animated={true} className={cx(tabsStyles.tabs, S.tabs)}>
        {dataSource.map((item: any) => (
          <TabPane tab={item.label} key={item.label}>
            {renderItem(item)}
          </TabPane>
        ))}
      </Tabs>
    </ModuleItem>
  );
}
