import React from 'react';

import image1 from './components/Scenes/images/finance.png';
import image2 from './components/Scenes/images/trade.png';
import image3 from './components/Scenes/images/manage.png';
import image4 from './components/Scenes/images/industry.png';
import image5 from './components/Able/images/icon-1.png';
import image6 from './components/Able/images/icon-2.png';
import image7 from './components/Able/images/icon-3.png';
import image8 from './components/Able/images/icon-4.png';

import image9 from './components/Partner/images/01.png';
import image10 from './components/Partner/images/02.png';
import image11 from './components/Partner/images/03.png';
import image12 from './components/Partner/images/04.png';
import image13 from './components/Partner/images/05.png';
import image14 from './components/Partner/images/06.png';
import image15 from './components/Partner/images/07.png';
import image16 from './components/Partner/images/08.png';
import image17 from './components/Partner/images/09.png';
import image18 from './components/Partner/images/10.png';
import image19 from './components/Partner/images/11.png';
import image20 from './components/Partner/images/12.png';
import image21 from './components/Partner/images/13.png';
import image22 from './components/Partner/images/14.png';
import image23 from './components/Partner/images/15.png';
import image24 from './components/Partner/images/16.png';
import image27 from './components/Partner/images/17.png';
import image28 from './components/Partner/images/18.png';

import image25 from './components/Banner/images/01.png';
import image26 from './components/Banner/images/bg.png';

const dataSource = {
  banner: {
    title: '星火·链网（胶州）骨干节点',
    subtitle: (
      <>
        国家级新型基础设施链核心枢纽 <br />
        山东省首个骨干节点
      </>
    ),
    strongContent: `星火·链网`,
    content: `是为持续推进产业数字化转型，进一步提升区块链自主创新能力而谋划布局的，面向数字经济的，区块链与工业互联网协同创新的“国家级新型基础设施”。由中国信息通信研究院为主要承担单位，组建覆盖全国的区块链网络体系，促进基础设施发挥能效。星火·链网（胶州）骨干节点是山东省首个骨干节点，通过连接国家级新型基础设施链核心枢纽，目标打造以区块链为核心的产业集群，全面赋能胶州实体经济、实现当地产业聚集、产业创新。胶州骨干节点以胶州市为核心，立足胶东半岛，以供应链能力向外输出，辐射全国，连接国际，致力于将胶州市区域节点打造为国家级供应链行业服务平台。`,
    image: image25,
    backgroundImage: image26,
    video: 'http://oss.jz-xinghuo.com/video/release-conference.mp4',
  },
  scenes: [
    {
      label: '供应链金融',
      describe: `胶州骨干节点打造以区块链为核心的产业集群，链接产业端与金融机构端，加速实现供应链金融中的商流、资金流、物流、信息流四流合一，提高产业集群中企业抗风险能力，扩大企业融资规模。`,
      plan: (
        <>
          应收账款质押融资&nbsp;&nbsp;&nbsp;仓单融资&nbsp;&nbsp;&nbsp;金融监管仓&nbsp;&nbsp;&nbsp;贸易金融
        </>
      ),
      planLink: '/scenes/gyljr?prevMenu=scenes1',
      image: image1,
    },
    {
      label: '跨境贸易',
      describe: `胶州骨干节点以区块链技术为核心，通过数据存证及分布式结算系统，构建良好的跨境连通能力，为国际贸易中的跨境物流及跨境结算提供强有力支撑。`,
      plan: <>跨境物流&nbsp;&nbsp;&nbsp;跨境结算</>,
      planLink: '/scenes/kjmy?prevMenu=scenes2',
      image: image2,
    },
    {
      label: '智能制造',
      describe: `胶州骨干节点打造区块链技术为支撑的工业互联网的核心能力，在智能制造领域，实现从零部件到成品全生命周期数据的可追溯，可执行，可感知。使工业制造的不同参与方智能化，网络化，自动化，实现降本增效。`,
      plan: <>智能生产管控&nbsp;&nbsp;&nbsp;制造企业数字化</>,
      planLink: '/scenes/znzz?prevMenu=scenes3',
      image: image4,
    },
    {
      label: '溯源管理',
      describe: `胶州骨干节点运用区块链技术与工业互联网标识解析体系相结合，提供商品跨境及原产地供应链溯源整体解决方案。`,
      plan: <>制造企业数字化&nbsp;&nbsp;&nbsp;供应链溯源</>,
      planLink: '/scenes/sygl?prevMenu=scenes4',
      image: image3,
    },
  ],
  able: [
    {
      icon: image5,
      title: '公共数据',
      describe:
        '提供企业资讯、用户身份、知识产权、资讯服务等公共数据价值交换服务，通过用户之间点对点数据交互的方式，实现数字资产价值化交换、转移过程。',
    },
    {
      icon: image6,
      title: '标识资源',
      describe:
        '对新型分布式标识及现有标识体系的多标识根区数据资源管理服务，实现根区平等共治、资源自主管理。',
    },
    {
      icon: image7,
      title: '标识解析',
      describe:
        '提供同时兼容新型分布式标识体系和现有标识体系解析模式，实现多标识体系解析过程互联互通。',
    },
    {
      icon: image8,
      title: '国家背书',
      describe:
        '通过国家主链为行业子链背书，确保数据可信性，同时国家主链为各个节点和用户进行可信认证，保证在整个链群结构中的天然可信性。',
    },
  ],
  Honor: [
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
    {
      src: 'https://dummyimage.com/644x444',
    },
  ],
  partner: [
    {
      label: '',
      src: image9,
    },
    {
      label: '',
      src: image10,
    },
    {
      label: '',
      src: image11,
    },
    {
      label: '',
      src: image27,
    },
    {
      label: '',
      src: image28,
    },
    {
      label: '',
      src: image12,
    },
    {
      label: '',
      src: image13,
    },
    {
      label: '',
      src: image14,
    },
    {
      label: '',
      src: image15,
    },
    {
      label: '',
      src: image16,
    },
    {
      label: '',
      src: image17,
    },
    {
      label: '',
      src: image18,
    },
    {
      label: '',
      src: image19,
    },
    {
      label: '',
      src: image20,
    },
    {
      label: '',
      src: image21,
    },
    {
      label: '',
      src: image22,
    },
    {
      label: '',
      src: image23,
    },
    {
      label: '',
      src: image24,
    },
  ],
};

export default dataSource;
