import React, { useState, useEffect } from 'react';
import SubBanner from '@components/SubBanner';
import { MainWidth } from '@components/MainLayout';
import { Pagination, Empty } from 'antd';
import { ITemp, ISelect, List } from './typings/index';
import { useHistory } from 'react-router-dom';

import { POST, prefix } from '@utils/lib-request/request';

// @ts-ignore
import S from './style.module.less';

import backgroundImage from './images/banner.png';
import foregroundImage from './images/banner_img.png';
import clockIcon from './images/clock_icon.png';

const dataSource = {
  SubBanner: {
    title: '新闻动态',
    subTitle: '打造万物互联的智能世界',
    backgroundImage,
    foregroundImage,
  },
};

export default function () {
  const [activeProduct, setActiveProduct] = useState('');
  const [activeScreen, setActiveScreen] = useState('');
  const [list, setList] = useState<List[]>([]);
  const [productSelect, setProductSelect] = useState<ISelect[]>([]);
  const [screenSelect, setScreenSelect] = useState<ISelect[]>([]);
  const [total, setTotal] = useState<number>();
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    caseScene: '',
    casePlatform: '',
  });
  const history = useHistory();

  useEffect(() => {
    getSelectData('caseScenes', '03');
    getSelectData('casePlatforms', '02');
  }, []);

  useEffect(() => {
    setActiveProduct(filter.casePlatform);
    setActiveScreen(filter.caseScene);
    getList(filter);
  }, [filter]);

  const handleSearch = (key: string, val: string) => {
    setFilter(filter => ({
      ...filter,
      [key]: val,
    }));
  };

  const getSelectData = (key: string, id: string) => {
    POST(`${prefix}/config/list`, { playId: id }).then((res: any) => {
      if (res) {
        key === 'casePlatforms' && setProductSelect(res);
        key === 'caseScenes' && setScreenSelect(res);
      }
    });
  };

  const getList = (params: ITemp) => {
    POST(`${prefix}/case/list`, { ...params }).then((res: any) => {
      if (res) {
        setList(res.list);
        setTotal(res.count);
      }
    });
  };

  const pageChange = (page: number) => {
    setFilter(filter => ({
      ...filter,
      page: page,
    }));
  };
  // 详情跳转
  const goDetail = (id: string) => {
    history.push({
      pathname: '/typical-detail',
      state: {
        id: id,
      },
    });
  };
  return (
    <>
      <SubBanner {...dataSource.SubBanner} />
      <div className={S.casesMain}>
        <MainWidth>
          {/* 筛选 */}
          <div className={S.selectBox}>
            <div className={S.selectRow}>
              <div className={S.label}>产品：</div>
              <div className={S.selectCont}>
                {productSelect.map(item => {
                  return (
                    <div
                      key={item.configValue}
                      className={`${S.contMain} ${
                        activeProduct === item.configValue ? S.active : ''
                      }`}
                      onClick={() => handleSearch('casePlatform', item.configValue)}
                    >
                      {item.configName}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={S.selectRow}>
              <div className={S.label}>应用场景：</div>
              <div className={S.selectCont}>
                {screenSelect &&
                  screenSelect.map(item => {
                    return (
                      <div
                        key={item.configValue}
                        className={`${S.contMain} ${
                          activeScreen === item.configValue ? S.active : ''
                        }`}
                        onClick={() => handleSearch('caseScene', item.configValue)}
                      >
                        {item.configName}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* 列表 */}
          <ul className={S.listUl}>
            {list.map((item, index) => {
              return (
                <li className={S.listLi} key={index} onClick={() => goDetail(item.caseId)}>
                  <div className={S.left}>
                    <img src={item.caseUrl} alt="" />
                  </div>
                  <div className={S.right}>
                    <div>
                      <h4 className={`${S.title} ${S.elSl}`} title={item.caseTitle}>
                        {item.caseTitle}
                      </h4>
                      <p className={S.subscribe} title={item.caseDescribe}>
                        {item.caseDescribe}
                      </p>
                    </div>
                    <div className={S.bottom}>
                      <div className={`${S.bottomL}`}>
                        {/* 蓝色 */}
                        {item.casePlatformName &&
                          item.casePlatformName.split(',').map((tag, tagIndex) => {
                            return (
                              <div className={`${S.tag} ${S.blueTag}`} title={tag} key={tagIndex}>
                                {tag}
                              </div>
                            );
                          })}
                        {/* 橙色 */}
                        {item.caseSceneName &&
                          item.caseSceneName.split(',').map((tag, tagIndex) => {
                            return (
                              <div className={`${S.tag} ${S.orangeTag}`} title={tag} key={tagIndex}>
                                {tag}
                              </div>
                            );
                          })}
                      </div>
                      <div className={S.bottomR}>
                        <div className={S.rMain}>
                          <img src={clockIcon} alt="" />
                          <span>{item.createdTime}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
            {!list.length && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          </ul>
          {/* 分页 */}
          <div className={S.pageBox}>
            <Pagination
              defaultCurrent={1}
              current={filter.page}
              onChange={pageChange}
              total={total}
              pageSize={filter.limit}
              hideOnSinglePage
            />
          </div>
        </MainWidth>
      </div>
    </>
  );
}
