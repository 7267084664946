import React from 'react';
import { Image } from 'antd';
import ModuleItem from '@components/ModuleItem';

export default function (props: any) {
  const { dataSource } = props;

  return (
    <ModuleItem title="发展历程" theme="white">
      <Image src={dataSource?.image} preview={false} width={'100%'} />
    </ModuleItem>
  );
}
