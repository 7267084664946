const routes = [
  {
    path: '/',
    text: '首页',
    component: 'index',
  },
  {
    path: '/scenes/:type',
    text: '应用场景',
    component: 'scenes',
  },
  {
    path: '/product-center',
    text: '产品中心',
    component: 'productCenter',
  },
  {
    path: '/typical-cases',
    text: '新闻动态',
    component: 'typicalCases',
  },
  {
    path: "/typical-detail",
    text: '新闻动态',
    component: 'typicalCases/detail'
  },
  {
    path: '/about-us',
    text: '关于我们',
    component: 'aboutUs',
  },
];

module.exports = routes;
