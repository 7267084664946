import React from 'react';
import { MainWidth } from '@components/MainLayout';
import cx from 'classnames';
import { isMobile } from '@utils/device';
// @ts-ignore
import Spc from './style.module.less';
// @ts-ignore
import Smobile from './style.mobile.module.less';

let S = Spc;

if (isMobile) {
  S = Smobile;
}

export default function (props: any) {
  const {
    title = '模块标题',
    renderTitle, // 自定义传入标题，函数返回JSX
    theme = '', // 背景颜色  'white'  'grey'
    className, // 类名
  } = props;

  return (
    <div className={cx(S.moduleItem, S[theme], className)}>
      <MainWidth>
        {typeof renderTitle === 'function' ? renderTitle() : <p className={S.title}>{title}</p>}

        {props.children}
      </MainWidth>
    </div>
  );
}
